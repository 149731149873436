<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="2" y1="4" x2="22" y2="4" />

        <line class="a" x1="2" y1="12" x2="16" y2="12" />

        <line class="a" x1="2" y1="20" x2="10" y2="20" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SortIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
