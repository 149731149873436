<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M10.5,19.75H6a1.5,1.5,0,0,1-1.5-1.5v-7.5" />
        <path
            class="a"
            d="M1.5,9.25l8.781-7.43a1.5,1.5,0,0,1,1.938,0l7.289,6.168"
        />
        <polyline class="a" points="16.5 1.75 19.5 1.75 19.5 4.75" />
        <path
            class="a"
            d="M18,14.875a.375.375,0,1,1-.375.375A.375.375,0,0,1,18,14.875"
        />
        <path
            class="a"
            d="M18,10.75a4.5,4.5,0,0,1,4.5,4.5c0,1.921-2.688,5.65-3.909,7.212a.75.75,0,0,1-1.182,0C16.188,20.9,13.5,17.171,13.5,15.25A4.5,4.5,0,0,1,18,10.75Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'HousePinIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
