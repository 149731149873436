<template>
    <ListItem :key="asset.id" class="u-bb no-padding">
        <router-link
            :to="assetLink"
            class="l-padded l-inline l-spread l-full-width l-gap-0"
        >
            <div class="l-inline l-gap-1">
                <AssetAvatar :tracker="asset" :size="42" />

                <div class="l-stack l-gap-0">
                    <!-- Name -->
                    <h3>{{ asset.asset_details.name }}</h3>

                    <!-- Asset identification -->
                    <div
                        v-if="asset.asset_details.identification"
                        class="t-small"
                    >
                        {{ asset.asset_details.identification }}
                    </div>

                    <!-- Location -->
                    <div
                        v-if="asset.location_details != undefined"
                        class="l-inline l-center-v l-gap-0 t-small"
                        :class="{ 'icon-green': isInsideHomeLocation }"
                    >
                        <HousePinIcon width="14" height="14" />

                        <span>{{ asset.location_details.name }}</span>
                    </div>

                    <!-- Tags -->
                    <div class="l-inline tags">
                        <div
                            v-for="(tag, index) in assetTags[asset.id]"
                            :key="index"
                            :class="tag.className"
                        >
                            {{ $t(tag.label) }}
                        </div>

                        <div
                            v-if="isOutsideHomeLocation"
                            class="list-item__tag blue"
                        >
                            {{ $t('outside') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="l-stack t-subtle l-end t-small">
                <div v-if="asset.last_message_timestamp" class="no-wrap">
                    <TimeAgo :from-datetime="asset.last_message_timestamp" />
                </div>

                <div v-if="batteryStatus" class="l-inline l-center l-gap-1">
                    <div class="no-wrap">{{ batteryStatus.value }} %</div>

                    <BatteryIcon
                        width="24"
                        height="24"
                        :battery-level-in-percent="batteryStatus.value"
                        :warn="batteryStatus.low"
                    />
                </div>
            </div>
        </router-link>
    </ListItem>
</template>

<script>
import { mapGetters } from 'vuex'

import AssetAvatar from './AssetAvatar.vue'
import BatteryIcon from './icons/BatteryIcon'
import HousePinIcon from './icons/HousePinIcon'
import ListItem from './ListItem'
import TimeAgo from './TimeAgo'

export default {
    name: 'AssetListItem',
    components: {
        AssetAvatar,
        BatteryIcon,
        HousePinIcon,
        ListItem,
        TimeAgo,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        assetDetailLinkBase: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('tracker', ['assetTags']),
        assetLink() {
            const base = this.assetDetailLinkBase || this.$route.path
            return base + '/' + this.asset.id
        },
        batteryStatus() {
            return this.asset.asset_details.sensor_data?.battery
        },
        isInsideHomeLocation() {
            const locationId = +this.$route.params.id
            return (
                this.$route.name === 'location' &&
                !this.asset.asset_details.static &&
                this.asset.location === locationId &&
                this.asset.asset_details.current_locations?.includes(locationId)
            )
        },
        isOutsideHomeLocation() {
            const locationId = +this.$route.params.id
            return (
                this.$route.name === 'location' &&
                !this.asset.asset_details.static &&
                this.asset.location === locationId &&
                !this.asset.asset_details.current_locations?.includes(
                    locationId
                )
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "beaconPosition": "Beacon Position",
        "inactive": "INACTIVE",
        "leftZone": "LEFT ZONE",
        "maintenance": "Maintenance due",
        "outside": "Outside"
    },
    "de": {
        "beaconPosition": "Beacon Position",
        "inactive": "INAKTIV",
        "leftZone": "ZONE VERLASSEN",
        "maintenance": "Wartung fällig",
        "outside": "Ausserhalb"
    },
    "fr": {
        "beaconPosition": "Position de la balise",
        "inactive": "INACTIF",
        "leftZone": "QUITTER LA ZONE",
        "maintenance": "Maintenance nécessaire",
        "outside": "A l'extérieur de"
    },
    "it": {
        "beaconPosition": "Beacon Posizione",
        "inactive": "INATTIVO",
        "leftZone": "ESCONO ZONA",
        "maintenance": "Manutenzione dovuta",
        "outside": "All'esterno di"
    }
}
</i18n>

<style lang="scss" scoped>
.no-wrap {
    white-space: nowrap;
}

.icon-green {
    svg {
        color: darken($color-green, 5%);
    }
}

.tags {
    flex-wrap: wrap;

    & > div {
        margin: 0 4px 4px 0;
        white-space: nowrap;
    }
}
</style>
